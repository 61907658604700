import {
  NMAAHCPropTypes,
  Theme,
  ThemeContext,
  useScrollObserver,
} from "assets";
import { ActionButton, FormattedText, TextPill } from "atoms";
import classNames from "classnames";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";

import * as styles from "./checkpoint.module.scss";

const Checkpoint = ({
  backgroundCover,
  backgroundImage,
  buttonText,
  text,
  fontColor,
  images,
  includeSeparator,
  isNew,
  label,
  horizontalLayout,
  onButtonClick,
  title,
  uri,
  url,
}) => {
  const ref = useRef();
  const scrolledClasses = useScrollObserver(ref);
  const isExternal = !uri && url;
  const { theme, fontType, pillarTitleColor } = useContext(ThemeContext);

  const titleClass = classNames(styles.title, fontType, {
    [styles.yellowTitle]: !fontColor && Theme.DarkBackgrounds.includes(theme),
    [styles.spacer]: !text,
  });

  const wrapperClass = classNames(styles.imgWrapper, {
    [styles.imgShift]: !text,
  });

  const className = classNames(
    "container-fluid",
    styles.checkpoint,
    scrolledClasses,
    {
      [styles.lightFont]: fontColor === "light",
      [styles.darkFont]: fontColor === "dark",
      [styles.backgroundImage]: backgroundImage,
      [styles.backgroundCover]: backgroundCover,
    }
  );

  const horizontalLayoutClass = classNames({
    [styles.horizontalContent]:
      horizontalLayout && images.length && text !== null,
    row: horizontalLayout && images.length && text !== null,
  });

  const imageMaxHeightClass = classNames({
    [styles.imageMaxHeight]: buttonText && !text,
  });

  return (
    <div
      className={className}
      data-testid="checkpoint"
      ref={ref}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      {includeSeparator && <div className={styles.separator} />}
      <div className={horizontalLayoutClass}>
        <div
          className={classNames(styles.ctnText, {
            "col-sm-10 col-lg-offset-1 col-lg-5": horizontalLayout,
          })}
        >
          {isNew && (
            <TextPill
              size={NMAAHCPropTypes.SizeSmall}
              style={fontColor || theme === "light" ? "dark" : "default"}
              text="New"
            />
          )}
          <p className={styles.eyebrow}>{label}</p>
          <FormattedText
            className={titleClass}
            outerElement={<h2 />}
            style={{ color: pillarTitleColor }}
            text={title}
            deepLink
          />
          <FormattedText
            className={classNames(styles.description, {
              freightSerif: theme !== Theme.Afrofuturism,
            })}
            text={text}
            theme={fontColor === "light" ? "dark" : theme}
          />
          <div className={styles.btnWrapper}>
            <ActionButton
              href={isExternal ? url : null}
              icon={isExternal ? "leave-arrow" : null}
              onClick={onButtonClick}
              screenReaderText={`. Go to ${title}`}
              target={isExternal ? "_blank" : null}
              text={buttonText}
              textPlacement="left"
              to={uri ? `/${uri}` : null}
            />
          </div>
        </div>
        <div className={wrapperClass}>
          {images?.length === 1 && (
            <GatsbyImage
              alt={images[0].altText}
              className={imageMaxHeightClass}
              data-testid="image"
              image={getImage(images[0].imageFile)}
            />
          )}
          {images?.length > 1 && (
            <div className={styles.scatteredPictureWrapper}>
              {images.map((img, ind) => {
                return (
                  <div className={styles.scatteredPicture} key={ind}>
                    <GatsbyImage
                      alt={""}
                      data-testid="image"
                      image={getImage(img.imageFile)}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Checkpoint.propTypes = {
  backgroundCover: PropTypes.bool,
  backgroundImage: PropTypes.string,
  buttonText: PropTypes.string,
  fontColor: PropTypes.string,
  horizontalLayout: PropTypes.bool,
  images: PropTypes.arrayOf(NMAAHCPropTypes.Image),
  includeSeparator: PropTypes.bool,
  isNew: PropTypes.bool,
  label: PropTypes.string,
  onButtonClick: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
  uri: PropTypes.string,
  url: PropTypes.string,
};

Checkpoint.defaultProps = {
  includeSeparator: true,
};

/**
 * The GraphQL fragment for retrieving Checkpoint data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const CheckpointFragment = graphql`
  fragment CheckpointFragment on CraftAPI_componentList_checkpoint_BlockType {
    checkpointTitle
    id
    label
    text
    includeSeparator
    isNew
    fontColor
    backgroundImage {
      url
    }
    backgroundCover
    internalLink {
      uri
    }
    externalLink
    buttonText
    horizontalLayout
    images {
      ... on CraftAPI_image_Asset {
        altText
        filename
        url
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 520)
          }
        }
      }
    }
  }
`;

const convert = (data) => {
  const { images } = data;

  return (
    <Checkpoint
      backgroundCover={data.backgroundCover}
      backgroundImage={data.backgroundImage?.[0]?.url}
      buttonText={data.buttonText}
      fontColor={data.fontColor}
      horizontalLayout={data.horizontalLayout}
      images={images}
      includeSeparator={data.includeSeparator}
      isNew={data.isNew}
      key={data.id}
      label={data.label}
      text={data.text}
      title={data.checkpointTitle}
      uri={data.internalLink?.[0]?.uri}
      url={data.externalLink}
    />
  );
};

export { CheckpointFragment, convert, Checkpoint as default };
