// extracted by mini-css-extract-plugin
export var actionBtn = "action-button-module--actionBtn---DhOc";
export var arrowRight = "action-button-module--arrowRight--WA-lz";
export var avatar = "action-button-module--avatar--5iQAm";
export var black = "action-button-module--black--0wztz";
export var constellationText = "action-button-module--constellationText--JlyoT";
export var drag = "action-button-module--drag--35pxd";
export var icon = "action-button-module--icon--PX45U";
export var iconWrapperDefault = "action-button-module--iconWrapperDefault--gFUkZ";
export var leaveArrow = "action-button-module--leaveArrow--Jlw9j";
export var leftCaret = "action-button-module--leftCaret--9rJXL";
export var onlyText = "action-button-module--onlyText--W8P0O";
export var onlyTextSlim = "action-button-module--onlyTextSlim--0vn-V";
export var play = "action-button-module--play--lZENW";
export var rightCaret = "action-button-module--rightCaret--WYWg6";
export var rightIcon = "action-button-module--rightIcon--xw6xp";
export var search = "action-button-module--search--I3Txk";
export var secondaryActionBtn = "action-button-module--secondaryActionBtn--VsUgr";
export var slim = "action-button-module--slim--T5EeA";
export var text = "action-button-module--text--xiysq";
export var undo = "action-button-module--undo--pLekS";
export var yellow = "action-button-module--yellow--yQV2y";