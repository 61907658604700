import { Theme } from "assets";
import classNames from "classnames";
import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./breadcrumb-navigation.module.scss";

const BreadcrumbNavigation = ({ activePageId, parentTitle, parentUri }) => {
  const navClasses = classNames(
    "row",
    "between-xs",
    "middle-xs",
    "center-sm",
    Theme.addClass(Theme.Dark, true),
    styles.breadcrumbNavigation
  );

  const desktopBackLinkClasses = classNames(styles.parentTitle);

  return (
    <nav
      aria-label="Breadcrumbs"
      className={navClasses}
      data-testid="breadcrumb-navigation"
    >
      <div className="hidden-tablet hidden-desktop col-xs-1 col-xs-offset-1">
        <Link data-testid="mobile-parent-link" to={`/${parentUri}`}>
          <i
            aria-label="Return to Parent"
            className={`${styles.backIcon} icon-back`}
          />
        </Link>
      </div>
      <div className="col-xs-8 col-sm-12">
        <div className="row center-xs">
          <Link
            className="hidden-mobile"
            data-testid="desktop-parent-link"
            to={`/${parentUri}`}
          >
            <span className={desktopBackLinkClasses}>{parentTitle}</span>
          </Link>
          <span aria-current="page">{activePageId}</span>
        </div>
      </div>
      <div className="hidden-tablet hidden-desktop col-xs-2" />
    </nav>
  );
};

BreadcrumbNavigation.propTypes = {
  activePageId: PropTypes.string.isRequired,
  parentTitle: PropTypes.string.isRequired,
  parentUri: PropTypes.string.isRequired,
};

const BreadcrumbNavigationFragment = graphql`
  fragment BreadcrumbNavigationFragment on CraftAPI_exhibit_detail_Entry {
    title
    parent {
      title
      uri
    }
  }
`;

/**
 * Converts the provided breadcrumb navigation data into a breadcrumb component
 *
 * @param breadcrumbNavData  the GraphQL response data
 * @returns               the breadcrumb navigation
 */
const convert = (breadcrumbNavData) => {
  return (
    <BreadcrumbNavigation {...breadcrumbNavData} key="breadcrumb-navigation" />
  );
};

export {
  BreadcrumbNavigationFragment,
  convert,
  BreadcrumbNavigation as default,
};
