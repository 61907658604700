import { NMAAHCPropTypes, Theme } from "assets";
import { Listen } from "atoms";
import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./text-hero.module.scss";

const TextHero = ({
  audioAsset,
  audioClosedCaption,
  image,
  imageObjectFit,
  imageObjectPosition,
  pageType,
  subtitle,
  title,
}) => {
  const sectionId = title;

  const sectionClass = classNames(
    "col-xs-12",
    styles.hero,
    Theme.addClass(Theme.Dark, true),
    {
      [styles.detailHero]: pageType === "detail",
      [styles.chapterHero]: pageType === "chapter",
    }
  );

  const textOverlayClass = classNames(
    styles.textOverlay,
    styles.collapseOverlay
  );

  return (
    <section className={sectionClass} data-testid="text-hero">
      {image && (
        <GatsbyImage
          alt={image.altText}
          data-testid="text-hero-image"
          formats={["auto", "webp", "avif"]}
          image={getImage(image.imageFile)}
          layout="fixed"
          objectFit={imageObjectFit}
          objectPosition={imageObjectPosition}
          style={{
            gridArea: "1/1",
            marginTop: 0,
          }}
        />
      )}
      <div
        style={{
          // By using the same grid area for both, they are stacked on top of each other
          gridArea: "1/1",
          position: "relative",
          display: "grid",
        }}
      >
        <div className={styles.bgOverlay}>
          <div className={textOverlayClass} id={sectionId}>
            <div className={styles.subtitleContainer}>
              {subtitle && (
                <p
                  aria-hidden="true"
                  className={styles.subtitle}
                  data-testid="subtitle"
                >
                  {subtitle}
                </p>
              )}
              {audioAsset?.title && audioAsset?.url && (
                <Listen
                  audioAsset={audioAsset}
                  audioClosedCaption={audioClosedCaption}
                  className={styles.listen}
                  data-testid="listen"
                />
              )}
            </div>
            <h2 className={styles.titleContainer} data-testid="title">
              <span className="visually-hidden">{subtitle}</span>
              {title}
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
};

TextHero.propTypes = {
  audioAsset: NMAAHCPropTypes.Audio,
  audioClosedCaption: PropTypes.string,
  image: NMAAHCPropTypes.Image,
  imageObjectFit: PropTypes.oneOf(["contain", "cover", "none", "scale-down"]),
  imageObjectPosition: PropTypes.string,
  pageType: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default TextHero;
