// extracted by mini-css-extract-plugin
export var active = "sibling-navigation-module--active--RQRjA";
export var backBtn = "sibling-navigation-module--back-btn--qkKLg";
export var expandButton = "sibling-navigation-module--expandButton--IW3Yw";
export var expanded = "sibling-navigation-module--expanded--Rxv0p";
export var expander = "sibling-navigation-module--expander--qLUBq";
export var fixedNav = "sibling-navigation-module--fixedNav--DZqDO";
export var fullWidth = "sibling-navigation-module--fullWidth--D1Ti2";
export var hidden = "sibling-navigation-module--hidden---aVg6";
export var hide = "sibling-navigation-module--hide--DRzHR";
export var icon = "sibling-navigation-module--icon--QwDDD";
export var leftNav = "sibling-navigation-module--leftNav--uTrRv";
export var pageLabel = "sibling-navigation-module--pageLabel--Q7FEy";
export var pageTitle = "sibling-navigation-module--pageTitle--bnTPj";
export var parentTitle = "sibling-navigation-module--parentTitle--y+-D8";
export var rightNav = "sibling-navigation-module--rightNav--CcNcX";
export var separator = "sibling-navigation-module--separator--YsUos";
export var siblingNavigation = "sibling-navigation-module--siblingNavigation--c+3Os";
export var spacer = "sibling-navigation-module--spacer--bzE24";
export var thumbnail = "sibling-navigation-module--thumbnail--E2B+s";
export var toTopButton = "sibling-navigation-module--toTopButton--Zqc36";
export var verticalScroll = "sibling-navigation-module--verticalScroll--vwgw9";