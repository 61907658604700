import { NMAAHCPropTypes, Theme } from "assets";
import { IIIFContainer, NMAAHCModalWindow } from "atoms";
import PropTypes from "prop-types";
import React from "react";

const ImageModal = ({
  audioAsset,
  showModal,
  onClose,
  image,
  title,
  subtitle,
  label,
  text,
  theme,
}) => {
  return (
    <NMAAHCModalWindow
      audioAsset={audioAsset}
      label={label}
      metadata={{
        copyright: image.copyright,
        creditLine: image.creditLine,
        displayTitle: image.displayTitle,
        linkField: image.linkField,
        objectName: image.objectName,
        objectNumber: image.objectNumber,
        objectTitle: image.objectTitle,
        subjectMedium: image.subjectMedium,
      }}
      onClose={onClose}
      showModal={showModal}
      subtitle={subtitle}
      text={text}
      theme={theme}
      title={title}
    >
      <div className="row center-xs">
        <div className="col-xs-12">
          <IIIFContainer image={image} />
        </div>
      </div>
    </NMAAHCModalWindow>
  );
};

ImageModal.propTypes = {
  audioAsset: NMAAHCPropTypes.Audio,
  image: NMAAHCPropTypes.Image.isRequired,
  label: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  ...Theme.PropType,
};

ImageModal.defaultProps = {
  label: "Image modal",
};

export default ImageModal;
