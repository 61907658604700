// extracted by mini-css-extract-plugin
export var backgroundCover = "text-promo-module--backgroundCover--0DLrg";
export var backgroundImage = "text-promo-module--backgroundImage--K0CXD";
export var center = "text-promo-module--center--BQQDx";
export var darkFont = "text-promo-module--darkFont--XIOW8";
export var leftAlign = "text-promo-module--leftAlign--Js5vy";
export var leftImage = "text-promo-module--leftImage--MFFyj";
export var lightFont = "text-promo-module--lightFont--VRE5R";
export var rightImage = "text-promo-module--rightImage--320kr";
export var textPromo = "text-promo-module--textPromo--QsV--";
export var twoCol = "text-promo-module--twoCol--QtulK";
export var withComponentSpacing = "text-promo-module--withComponentSpacing--0V+x1";