import { NMAAHCPropTypes } from "assets";

export const pillarSiblingNavAugmentor = (pillarData) => {
  pillarData.componentList
    .filter((c) => {
      return c.__typename === "CraftAPI_componentList_chapterListing_BlockType";
    })
    .map((c) => {
      Object.assign(c, {
        condensed: true,
        level: "chapter",
        pages: pillarData.children,
        parentIndex: pillarData.parent?.children.findIndex(
          (child) => child.id === pillarData.id
        ),
        parentTitle: pillarData.title,
        chronoPresentation:
          pillarData.parent?.presentationType !==
          NMAAHCPropTypes.EXHIBIT_TYPE_THEMED,
      });
    });

  return pillarData;
};
