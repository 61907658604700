import { Display, NMAAHCPropTypes, Theme } from "assets";
import { FormattedText } from "atoms";
import classNames from "classnames";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { SubNavList } from "molecules";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import * as styles from "./sibling-navigation.module.scss";

const LABEL_MAP = {};
LABEL_MAP.exhibit = { label: "Exhibit", shortLabel: "Ex" };
LABEL_MAP.pillar = {
  label: "Part",
  shortLabel: "Part",
  parent: LABEL_MAP.exhibit,
};
LABEL_MAP.chapter = {
  label: "Chapter",
  shortLabel: "Ch.",
  parent: LABEL_MAP.pillar,
};
LABEL_MAP.cluster = {
  label: "Section",
  shortLabel: "Sec.",
  parent: LABEL_MAP.chapter,
};

// Height of this navigation, used for switch over points when multiple navigations are used
const NAV_HEIGHT = 72;

// Amount of padding above the section navigation blocks
const NAV_BLOCK_OFFSET = 170;

const SiblingNavigation = ({
  activePageId,
  chronoPresentation,
  beginRef,
  condensed,
  endRef,
  level,
  pages,
  pageIndex,
  pageTitle,
  parentIndex,
  parentTitle,
  parentUri,
}) => {
  // If begin ref is specified, that's when we want to begin showing the sticky nav - otherwise show even when scrolled to the top (0)
  let minScroll = 0;
  if (beginRef?.current) minScroll = beginRef.current.offsetTop - NAV_HEIGHT;
  // In some cases we are passing in a beginRef, but the reference hasn't been set (happens on scroll), so set something to a little past 0px
  else if (beginRef) minScroll = 10;
  // If end ref is specified, that's when we want to stop showing the sticky nav - otherwise show regardless of how far scrolled down
  const maxScroll = endRef?.current
    ? endRef.current.offsetTop - NAV_HEIGHT
    : Number.MAX_SAFE_INTEGER;
  const [scrollY, setScrollY] = useState(-1);
  const [expanded, setExpanded] = useState(condensed);

  const onPageScroll = () => {
    const newScrollY = window.scrollY;

    // User has scrolled outside of the target min/max so collapse the nav in addition to hiding it
    if (
      scrollY >= minScroll &&
      scrollY <= maxScroll &&
      (newScrollY < minScroll || newScrollY > maxScroll)
    )
      setExpanded(false);

    setScrollY(newScrollY);
  };

  // Sticky version listens to page scroll events
  useEffect(() => {
    if (!condensed) {
      document.addEventListener("scroll", onPageScroll);
      // initial scroll check on load
      onPageScroll();

      return () => {
        document.removeEventListener("scroll", onPageScroll);
      };
    }
  });

  const navClass = classNames(
    "container-fluid",
    styles.siblingNavigation,
    Theme.addClass(Theme.Dark, true, true),
    {
      [styles.fixedNav]: !condensed,
      [styles.verticalScroll]: !condensed & expanded,
      [styles.hidden]: scrollY < minScroll || scrollY > maxScroll,
      hidden: !condensed && scrollY < 0, // wait for the page to initialize
    }
  );

  const rightNavClass = classNames(
    styles.rightNav,
    "col-xs-8",
    "col-md-7",
    "col-lg-6",
    {
      "col-lg-offset-1": condensed,
      "end-xs": !condensed,
    }
  );

  const buttonClass = classNames(styles.expandButton, {
    hidden: condensed,
    [styles.expanded]: expanded,
  });

  const toTopClass = classNames(styles.toTopButton, {
    hidden: condensed,
  });

  const separatorClass = classNames(styles.separator, {
    hidden: !condensed,
  });

  const expanderClass = classNames(styles.expander, {
    [styles.expanded]: expanded,
    [styles.fullWidth]: condensed,
    "col-xs-offset-0": condensed,
    "col-xs-12": condensed,
    "col-lg-offset-1": condensed,
    "col-lg-10": condensed,
    ...Theme.addClass(Theme.Dark, true),
  });

  if (!chronoPresentation) {
    LABEL_MAP.pillar.label = "Theme";
    LABEL_MAP.pillar.shortLabel = "Theme";
  }

  let parentLabel;
  if (parentIndex !== undefined) {
    const longLabel = `<span class="hidden-mobile">${LABEL_MAP[level].parent.label}</span>`,
      shortLabel = `<span aria-label=${LABEL_MAP[level].parent.label} class="hidden-tablet hidden-desktop" aria-hidden="true">${LABEL_MAP[level].parent.shortLabel}</span>`;

    parentLabel =
      chronoPresentation === true
        ? `${longLabel} ${shortLabel} ${String(parentIndex + 1).padStart(
          2,
          "0"
        )}`
        : `${longLabel} ${shortLabel}`;
  }

  let currentLabel;
  if (pageIndex !== undefined) {
    const longLabel = `<span class="hidden-mobile">${LABEL_MAP[level].label}</span>`,
      shortLabel = `<span aria-label=${LABEL_MAP[level].label} class="hidden-tablet hidden-desktop" aria-hidden="true">${LABEL_MAP[level].shortLabel}</span>`;

    currentLabel =
      chronoPresentation === true
        ? `${longLabel} ${shortLabel} ${String(pageIndex + 1).padStart(
          2,
          "0"
        )} - ${pageTitle} `
        : `${pageTitle}`;
  }

  const numPages = `${pages?.length || 0} ${LABEL_MAP[level].label}${
    pages?.length === 1 ? "" : "s"
  }`;
  const showPages = `Show ${numPages}`;
  const totalPages = `${pages?.length} Total ${LABEL_MAP[level].label}${
    pages?.length === 1 ? "" : "s"
  }`;
  let desktopPageLabel;
  if (condensed && !expanded) desktopPageLabel = showPages;
  else if (condensed && expanded) desktopPageLabel = totalPages;
  else desktopPageLabel = numPages;

  let mobilePageLabel = numPages;

  const expandToggle = (e) => {
    e?.preventDefault();
    setExpanded(!expanded);
  };

  const subNavData = pages?.map((page, i) => ({
    activePage: page.id === activePageId,
    byline: page.shortDescription,
    img: page.largeImage?.[0],
    imgAlt: `${LABEL_MAP[level].label} ${i + 1} Thumbnail`,
    subtitle: `${LABEL_MAP[level].label} ${String(i + 1).padStart(2, "0")}`,
    title: page.title,
    to: `/${page.uri}`,
  }));

  const LabelElement = condensed ? "span" : "button";

  return (
    <nav
      aria-label="Breadcrumbs"
      className={navClass}
      data-testid="sibling-navigation"
      key="sibling-navigation"
    >
      <div className="row middle-xs">
        {!condensed && (
          <div
            className={`col-lg-offset-1 col-xs-4 col-md-5 col-lg-4 ${styles.leftNav}`}
          >
            <Link to={`/${parentUri}`}>
              <i
                className={`${styles.backBtn} icon-back`}
                data-testid="parent-link"
                role="presentation"
              />
              <span className={styles.parentTitle} data-testid="parent-label">
                <FormattedText text={parentLabel} />
              </span>
            </Link>
            <span className={`hidden-mobile hidden-tablet ${styles.pageTitle}`}>
              <FormattedText text={currentLabel} />
            </span>
          </div>
        )}
        <div className={rightNavClass}>
          <LabelElement
            className={styles.pageLabel}
            onClick={condensed ? undefined : expandToggle}
          >
            <i className={`${styles.icon} icon-stacked-box`} />
            <strong
              className="hidden-mobile hidden-tablet"
              data-testid="page-label"
            >
              {desktopPageLabel}
            </strong>
            <strong className="hidden-desktop">{mobilePageLabel}</strong>
          </LabelElement>
          {pages
            ?.filter((page) => Boolean(page.largeImage))
            .map((page, i) => (
              <Link
                className={classNames("hidden-mobile", styles.thumbnail, {
                  hidden: expanded,
                  [styles.active]: page.id === activePageId,
                })}
                data-testid={`page-${i + 1}-thumbnail`}
                key={page.id}
                to={`/${page.uri}`}
              >
                <GatsbyImage
                  alt={`${LABEL_MAP[level].label} ${i + 1}: ${page.title}`}
                  image={getImage(page.largeImage?.[0]?.imageFile)}
                />
              </Link>
            ))}
          <button
            aria-label="Expand"
            className={buttonClass}
            data-testid="expand-button"
            onClick={expandToggle}
          >
            <span className="hidden-desktop">Hide</span>
            <span className="hidden-mobile hidden-tablet">
              {`Hide ${LABEL_MAP[level].label}${
                pages?.length === 1 ? "" : "s"
              }`}
            </span>
            <i className="icon-expand-vertical" role="presentation" />
          </button>
          <button
            aria-label="Back To Top"
            className={toTopClass}
            onClick={() => {
              // Scroll to the top or before the cluster nav element
              const top =
                level === "cluster" ? minScroll + NAV_BLOCK_OFFSET : 0;
              window.scrollTo({ top, behavior: "smooth" });
            }}
          >
            <span>Back to Top</span>
            <i alt="Back to Top" className="icon-top" />
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-lg-offset-1 col-lg-10">
          <div className={separatorClass} />
        </div>
      </div>
      <div className="row">
        <div className={expanderClass} data-testid="expander">
          <SubNavList
            chronoPresentation={chronoPresentation}
            closeSubNav={() => setExpanded(false)}
            data={subNavData}
            fullWidth={condensed}
            mode="sibling"
            showBylines={condensed ? Display.ShowDesktop : Display.Hidden}
            showDividers={condensed ? Display.ShowDesktop : Display.Hidden}
            theme={Theme.Dark}
          />
        </div>
      </div>
    </nav>
  );
};

SiblingNavigation.propTypes = {
  activePageId: PropTypes.string,
  beginRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  chronoPresentation: PropTypes.bool,
  condensed: PropTypes.bool,
  endRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  level: PropTypes.oneOf(["chapter", "cluster", "pillar"]),
  pageIndex: PropTypes.number,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      largeImage: PropTypes.arrayOf(NMAAHCPropTypes.Image),
      shortDescription: PropTypes.string,
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    })
  ).isRequired,
  pageTitle: PropTypes.string,
  parentIndex: PropTypes.number,
  parentTitle: PropTypes.string,
  parentUri: PropTypes.string,
};

SiblingNavigation.defaultProps = {
  condensed: false,
};

const ChapterNavigationFragment = graphql`
  fragment ChapterNavigationFragment on CraftAPI_exhibit_chapter_Entry {
    id
    largeImage: coverImage {
      ... on CraftAPI_image_Asset {
        altText
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 300)
          }
        }
        url(transform: "subnav")
      }
    }
    shortDescription
    title
    uri
  }
`;

const PillarNavigationFragment = graphql`
  fragment PillarNavigationFragment on CraftAPI_exhibit_pillar_Entry {
    id
    largeImage: coverImage {
      ... on CraftAPI_image_Asset {
        altText
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 300)
          }
        }
        url(transform: "subnav")
      }
    }
    shortDescription
    uri
    title
  }
`;

/**
 * Converts the provided page navigation data into a sibling navigation
 *
 * @param navData  the GraphQL response data
 * @returns        the sibling navigation
 */
const convert = (navData) => {
  return <SiblingNavigation {...navData} key={`${navData.level}-navigation`} />;
};

export {
  ChapterNavigationFragment,
  convert,
  SiblingNavigation as default,
  PillarNavigationFragment,
};
