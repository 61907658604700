import {
  NMAAHCPropTypes,
  PageTypes,
  Theme,
  ThemeContext,
  useWindowDimensions,
} from "assets";
import {
  ActionButton,
  ConstellationButton,
  ConstellationButtonStars,
  FormattedText,
  ToggleSwitch,
} from "atoms";
import classNames from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
import { TimelineNavigation } from "molecules";
import { PageLayout } from "organisms";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

import * as styles from "./constellation-page.module.scss";

const ConstellationPage = ({
  description,
  groups,
  subtitle,
  timelineMode,
  title,
}) => {
  const timelineGroups = [...groups].sort((a, b) => {
    const regex = /\D/g;
    const v1 = a?.date?.replace(regex, "");
    const v2 = b?.date?.replace(regex, "");
    if (!Number(v1) && Number(v2)) return 1;
    if (Number(v1) && !Number(v2)) return -1;
    if (Number(v1) > Number(v2)) return 1;
    if (Number(v1) < Number(v2)) return -1;
    return 0;
  });
  const { width } = useWindowDimensions();
  const TIMELINE_MODE = "timelineMode";
  const timelineModeState =
    typeof window !== "undefined"
      ? JSON.parse(window.sessionStorage.getItem(TIMELINE_MODE))
      : timelineMode;
  const [showTimeline, setShowTimeline] = useState(
    timelineModeState || timelineMode
  );
  const [startDate, setStartDate] = useState("1600");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [tickBarStyles, setTickBarStyles] = useState({
    width: "2000px",
    height: "8px",
    margin: "0 9.7rem",
  });
  const [slideMargin, setSlideMargin] = useState(0);
  const timelineNavHeight = useRef(64.7);
  const mainNavMobileHeight = 58;
  const [slideRefs, setSlideRefs] = useState();

  const isMobile = width > 0 && width <= parseInt(1024);

  const carouselProps = (isMobile) => {
    return {
      active: !isMobile,
      dragFree: true,
      align: "start",
      axis: isMobile ? "y" : "x",
      containScroll: "trimSnaps",
    };
  };

  const [emblaRef, emblaApi] = useEmblaCarousel(carouselProps(isMobile), [
    WheelGesturesPlugin(),
  ]);

  const setSelectedSlideByIndex = (index) => {
    const slide = document.getElementsByClassName(styles.buttonBox)[index - 1];
    const scrollOffset =
      slide.getBoundingClientRect().top +
      window.scrollY -
      timelineNavHeight.current -
      slideMargin -
      mainNavMobileHeight;
    window.scrollTo(0, scrollOffset);
  };

  const setNavHeight = () => {
    const navHeight = document?.getElementsByClassName(styles.navigation)[0]
      ?.offsetHeight;
    if (navHeight !== timelineNavHeight.current)
      timelineNavHeight.current = navHeight;
    return navHeight;
  };

  const setNavIndex = () => {
    if (!slideRefs) return null;
    const windowScrollY = Math.floor(window.scrollY + slideMargin * 1.05);
    if (windowScrollY < slideRefs[1].position) {
      setSelectedIndex(0);
    } else if (windowScrollY >= slideRefs[slideRefs.length - 1].position) {
      setSelectedIndex(slideRefs.length - 1);
    } else {
      const slide = slideRefs.find((o) => o.position >= windowScrollY);
      setSelectedIndex(slide?.index - 1);
    }
  };

  const getSlidePositions = () => {
    setNavHeight();
    const slides = Array.prototype.slice.call(
      document.getElementsByClassName(styles.buttonBox)
    );
    setSlideMargin(parseInt(getComputedStyle(slides[0]).marginTop, 10));
    setSlideRefs(
      timelineGroups.map((group, i) => {
        const ref = {
          date: group.date,
          index: i,
          position:
            slides[i]?.getBoundingClientRect().top +
            window.scrollY -
            mainNavMobileHeight -
            timelineNavHeight.current,
        };
        return ref;
      })
    );
  };

  const setTickBar = () => {
    const timelineItems = document.querySelectorAll(
      ".constellation-page-module--buttonBox--JkMif:not(.visually-hidden)"
    );
    const firstItemOffset = timelineItems[0]?.offsetLeft;
    const lastItemOffset = timelineItems[timelineItems.length - 1]?.offsetLeft;

    firstItemOffset &&
      lastItemOffset &&
      setTickBarStyles({
        width: `${lastItemOffset + 10 - firstItemOffset}px`,
        height: "8px",
        margin: "0 9.7rem",
      });
  };

  const displayNavigation = () => {
    return (
      <TimelineNavigation
        activeEvent={timelineGroups[selectedIndex]}
        events={timelineGroups}
        granularity={"century"}
        setSelectedSlideByIndex={setSelectedSlideByIndex}
        theme={Theme.Dark}
        isConstellation
      />
    );
  };

  useEffect(() => {
    const firstDate = timelineGroups[0]?.date;
    setStartDate(firstDate?.slice(0, -2) + "00");
    setTickBar();
    if (emblaApi) emblaApi.scrollTo(0);
    displayNavigation();
    setSlideMargin(
      parseInt(
        getComputedStyle(document.getElementsByClassName(styles.buttonBox)[0])
          .marginTop,
        10
      )
    );
    getSlidePositions();
    window.sessionStorage.setItem(TIMELINE_MODE, JSON.stringify(showTimeline));

    window.addEventListener("resize", setTickBar);
    window.addEventListener("resize", displayNavigation);
    return () => {
      window.removeEventListener("resize", setTickBar);
      window.removeEventListener("resize", displayNavigation);
    };
  }, [showTimeline]);

  useEffect(() => {
    if (showTimeline) {
      window.addEventListener("scroll", setNavIndex);
      window.addEventListener("resize", setNavIndex);
      setNavIndex();
      return () => {
        window.removeEventListener("scroll", setNavIndex);
        window.removeEventListener("resize", setNavIndex);
      };
    }
  }, [slideRefs]);

  useEffect(() => {
    if (showTimeline) {
      getSlidePositions();
      window.addEventListener("resize", getSlidePositions);
      return () => {
        window.removeEventListener("resize", getSlidePositions);
      };
    }
  }, [slideMargin, showTimeline]);

  const displayConstellations = (transitionCssIn) => {
    const groupDisplay = showTimeline ? timelineGroups : groups;
    return groupDisplay.map((group, i) => {
      // Pull the data from the lowest level original content
      let referencePage = group.reference?.[0];
      const referencePageImage = referencePage?.coverImage?.[0];

      // Override that data if it is set on the constellation grouping page
      let image = group.constellationImage?.[0] || referencePageImage;

      return (
        <CSSTransition
          classNames={{
            enter: styles.buttonEnter,
            enterActive: styles.buttonEnterActive,
            exit: "visually-hidden",
          }}
          in={transitionCssIn}
          key={group.id}
          timeout={2000}
          unmountOnExit
        >
          <div className={styles.buttonBox}>
            <div className={styles.date}>{group.date}</div>
            <ConstellationButton
              image={image}
              isTimeline={showTimeline}
              tag={group.tag}
              title={group.title || referencePage?.title}
              to={`/${group.uri}`}
              fromClick
            />
            {showTimeline && <ConstellationButtonStars />}
          </div>
        </CSSTransition>
      );
    });
  };

  const displayTimeline = () => (
    // CSSTransition class must be used to manage the state of the constellation buttons so that we can apply
    // css transitions to them. Becuase of this, we must visually hide the timeline when we are not in timeline mode
    <div
      className={classNames("embla", styles.outerSlideContainer, {
        "visually-hidden": !showTimeline,
      })}
      ref={emblaRef}
    >
      <div
        className={classNames("embla__container", styles.innerSlideContainer)}
        data-testid="slide-container"
      >
        {displayConstellations(showTimeline)}
        <div
          className={styles.timelineTicks}
          style={{
            width: tickBarStyles.width,
            height: tickBarStyles.height,
            margin: tickBarStyles.margin,
          }}
        ></div>
      </div>
    </div>
  );

  return (
    <ThemeContext.Provider
      value={{
        theme: Theme.Black,
        fontType: "",
      }}
    >
      <PageLayout
        pageType={PageTypes.CONSTELLATION}
        theme={Theme.Black}
        title={title}
      >
        <div className={`container-fluid ${styles.constellationIntro}`}>
          <h1>{title}</h1>
          <p className={styles.subtitle}>{subtitle}</p>
          <FormattedText className={styles.description} text={description} />
          <div
            className={classNames(styles.timelineToggle, {
              [styles.toggleActive]: showTimeline,
            })}
          >
            <ToggleSwitch
              checked={showTimeline}
              onClick={() => {
                setShowTimeline(!showTimeline);
              }}
              text="Timeline Mode"
            />
          </div>
        </div>
        <div
          className={classNames(styles.container, "row center-xs", {
            [styles.constellationTimeline]: showTimeline,
          })}
          data-testid="constellation-page"
        >
          {showTimeline && isMobile && (
            <div className={styles.navigation}>{displayNavigation()}</div>
          )}

          {showTimeline && (
            <div className={classNames(styles.dateRange, styles.startDate)}>
              {startDate}
            </div>
          )}
          {displayTimeline()}
          {displayConstellations(!showTimeline)}
          {showTimeline && (
            <div className={classNames(styles.dateRange)}>Today</div>
          )}
        </div>
        <div className="row center-xs hidden-desktop">
          <ActionButton
            icon={"arrow-up"}
            onClick={() => window.scrollTo({ top, behavior: "smooth" })}
            text={"Back To Top"}
            textPlacement={"left"}
            yellow
          />
        </div>
      </PageLayout>
    </ThemeContext.Provider>
  );
};

ConstellationPage.propTypes = {
  description: PropTypes.string.isRequired,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      constellationImage: PropTypes.arrayOf(NMAAHCPropTypes.Image),
      date: PropTypes.string,
      tag: PropTypes.string,
      title: PropTypes.string,
      uri: PropTypes.string,
    })
  ),
  subtitle: PropTypes.string,
  timelineMode: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

ConstellationPage.defaultProps = {
  groups: [],
  timelineMode: false,
};

export default ConstellationPage;
