import { NMAAHCPropTypes } from "assets";
import classNames from "classnames";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./action-button.module.scss";

const ActionButton = ({
  ariaDisabled,
  black,
  href,
  icon,
  image,
  yellow,
  onClick,
  screenReaderText,
  slim,
  secondaryCta,
  text,
  textPlacement,
  to,
  ...props
}) => {
  const className = classNames({
    [styles.actionBtn]: !secondaryCta,
    [styles.secondaryActionBtn]: secondaryCta,
    "large-font": secondaryCta,
    [styles.slim]: slim,
    [styles.black]: black,
    [styles.yellow]: yellow,
  });

  const textClass = classNames(styles.text, {
    [styles.onlyText]: !image && !icon && !slim,
    [styles.onlyTextSlim]: !image && !icon && slim,
  });

  const iconClassName = classNames({
    [styles.icon]: textPlacement === "right",
    [styles.rightIcon]: textPlacement === "left",
  });

  let Button = "button";
  let linkProps = {};
  if (to) {
    Button = Link;
    linkProps = { to };
  } else if (href) {
    Button = "a";
    linkProps = { href, target: "_blank" };
  }

  const icons = {
    play: {
      id: "play",
      styles: styles.play,
    },
    search: {
      id: "search",
      styles: styles.search,
    },
    "leave-arrow": {
      id: "arrow-up",
      styles: styles.leaveArrow,
    },
    "arrow-up": {
      id: "arrow-up",
    },
    "arrow-right": {
      id: "forward",
      styles: styles.arrowRight,
    },
    undo: {
      id: "undo",
      styles: styles.undo,
    },
    drag: {
      id: "drag",
      styles: styles.drag,
    },
  };

  return (
    <Button
      aria-disabled={ariaDisabled}
      className={className}
      data-testid="action-btn"
      onClick={!ariaDisabled ? onClick : null}
      {...linkProps}
      {...props}
    >
      {textPlacement === "left" && (
        <span className={textClass} data-testid="btn-text">
          {text}

          <span className="visually-hidden">{screenReaderText}</span>
        </span>
      )}
      {icon && (
        <span className={iconClassName}>
          <i
            className={`${icons[icon].styles} icon-${icons[icon].id}`}
            data-testid={`${icons[icon].id}-icon`}
          />
        </span>
      )}
      {textPlacement === "right" && (
        <span className={textClass} data-testid="btn-text">
          {text}

          <span className="visually-hidden">{screenReaderText}</span>
        </span>
      )}
      {textPlacement === "right" && image && (
        <GatsbyImage
          alt={image.altText}
          className={styles.avatar}
          data-testid="avatar"
          image={getImage(image.imageFile)}
        />
      )}
    </Button>
  );
};

ActionButton.propTypes = {
  ariaDisabled: PropTypes.bool,
  black: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.oneOf([
    "play",
    "search",
    "leave-arrow",
    "arrow-right",
    "arrow-up",
    "undo",
    "drag",
  ]),
  image: NMAAHCPropTypes.Image,
  onClick: PropTypes.func.isRequired,
  screenReaderText: PropTypes.string,
  secondaryCta: PropTypes.bool,
  slim: PropTypes.bool,
  text: PropTypes.string.isRequired,
  textPlacement: PropTypes.oneOf(["left", "right"]),
  to: PropTypes.string,
  yellow: PropTypes.bool,
};

ActionButton.defaultProps = {
  ariaDisabled: false,
  black: false,
  yellow: false,
  href: null,
  icon: null,
  image: null,
  screenReaderText: null,
  slim: false,
  secondaryCta: false,
  to: null,
  onClick: () => {},
  textPlacement: "right",
};

export default ActionButton;
