import { Theme } from "assets";
import { NMAAHCModalWindow } from "atoms";
import { MediaCarousel } from "molecules";
import PropTypes from "prop-types";
import React, { useState } from "react";

const MediaCarouselModal = ({
  showModal,
  onClose,
  media,
  title,
  subtitle,
  label,
  startIndex,
  onImageReveal,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(startIndex);

  const {
    audio,
    imageDescription,
    image: [
      {
        copyright,
        creditLine,
        displayTitle,
        linkField,
        objectName,
        objectNumber,
        objectTitle,
        subjectMedium,
      },
    ],
  } = media[selectedIndex];

  return (
    <NMAAHCModalWindow
      audioAsset={audio?.[0]}
      label={label}
      metadata={{
        copyright,
        creditLine,
        currentItem: selectedIndex + 1,
        displayTitle,
        linkField,
        objectName,
        objectNumber,
        objectTitle,
        subjectMedium,
        totalItems: media.length,
      }}
      onClose={onClose}
      showModal={showModal}
      subtitle={subtitle}
      text={imageDescription}
      theme={Theme.Dark}
      title={title}
    >
      <MediaCarousel
        media={media.map((medium) => {
          return {
            image: medium?.image?.[0],
            imageFile: medium?.imageFile || medium?.image?.[0].imageFile,
            altText: medium?.altText || medium?.image?.[0].altText,
            videoUrl:
              medium.video?.[0]?.embeddedAsset?.url || medium.video?.[0]?.url,
          };
        })}
        onSelectedIndex={(currentIndex) => {
          setSelectedIndex(currentIndex);
          onImageReveal(currentIndex);
        }}
        startIndex={startIndex}
      />
    </NMAAHCModalWindow>
  );
};

MediaCarouselModal.propTypes = {
  label: PropTypes.string,
  media: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onImageReveal: PropTypes.func,
  showModal: PropTypes.bool.isRequired,
  startIndex: PropTypes.number,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

MediaCarouselModal.defaultProps = {
  label: "Media Carousel Modal",
};

export default MediaCarouselModal;
