import { Theme } from "assets";
import { Card, Scroller } from "atoms";
import classNames from "classnames";
import { CardsScrollWrapper } from "molecules";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import * as styles from "./stories-landing-page-content.module.scss";

const StoriesLandingPageContent = ({ storyData, storyTagData }) => {
  const [filterTag, setFilterTag] = useState(null);
  const [filterTimePeriod, setFilterTimePeriod] = useState(null);
  const [showFilterList, setShowFilterList] = useState(false);
  const filterWrapperRef = useRef(null);
  const filterBoxRef = useRef(null);
  const filterListInnerContainer = useRef();
  const [randomSeed, setRandomSeed] = useState(Math.random());
  const [showTags, setShowTags] = useState(false);
  if (!randomSeed) setRandomSeed(Math.random());

  const setTagList = (type) => {
    let presentTagList = null;
    const presentTags = [];
    const tagList = [];
    storyTagData?.map((tag) => {
      if (tag.groupHandle === type) tagList.push(tag);
    });
    if (type === "topic") {
      storyData?.children.map((story) => {
        story.tags.map((tag) => {
          presentTags.indexOf(tag) === -1 ? presentTags.push(tag) : null;
        });
      });
    }
    if (type === "timePeriods") {
      storyData?.children.map((story) => {
        presentTags.indexOf(story.timePeriod) === -1
          ? presentTags.push(story.timePeriod)
          : null;
      });
    }
    presentTagList = tagList.filter((item) => presentTags.includes(item.title));
    presentTagList.sort();
    return presentTagList;
  };

  const displayFilterTags = () => {
    return (
      <div className={"container-fluid"}>
        <div className={styles.filterBoxContainer}>
          <div className={styles.filterBox}>
            Filter by
            <div
              className={styles.filterSelect}
              onClick={(e) => {
                if (
                  e.target === e.currentTarget ||
                  (!filterTag && !filterTimePeriod)
                ) {
                  setShowFilterList(!showFilterList);
                  // forces scroll bar to size to correct height on mobile
                  setTimeout(function () {
                    window.dispatchEvent(new Event("resize"));
                  }, 100);
                }
              }}
              ref={filterBoxRef}
            >
              {filterTag && (
                <span>
                  {filterTag}
                  <i
                    className={classNames("icon-close", styles.closeIcon)}
                    onClick={() => {
                      setFilterTag("");
                    }}
                  ></i>
                </span>
              )}
              {filterTimePeriod && (
                <span>
                  {filterTimePeriod}
                  <i
                    className={classNames("icon-close", styles.closeIcon)}
                    onClick={() => {
                      setFilterTimePeriod("");
                    }}
                  ></i>
                </span>
              )}
              {!filterTag && !filterTimePeriod && <span>All</span>}
              <i
                className={classNames("icon-forward", styles.carrot, {
                  [styles.openFilter]: showFilterList,
                })}
              />
            </div>
          </div>
          <div className={styles.storyCount}>
            {displayedCards?.length}{" "}
            {displayedCards?.length > 1 ? "Stories" : "Story"}
          </div>
          <div
            className={classNames(styles.filterList, {
              [styles.showFilterList]: showFilterList,
            })}
            ref={filterWrapperRef}
          >
            <div
              className={styles.filterListInnerContainer}
              ref={filterListInnerContainer}
            >
              <div className={classNames(styles.filterTags, styles.topicTags)}>
                <div className={styles.groupTitle}>Topics</div>
                <ul>
                  {tagList?.map((tag) => {
                    return (
                      <li
                        className={classNames({
                          [styles.activeTag]: filterTag === tag?.title,
                        })}
                        key={tag?.id}
                        onClick={() => {
                          setShowFilterList(
                            filterTag !== tag?.title ? false : true
                          );
                          setFilterTag(
                            filterTag !== tag?.title ? tag?.title : ""
                          );
                          setFilterTimePeriod("");
                        }}
                      >
                        {tag?.title}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className={classNames(styles.filterTags, styles.periodTags)}>
                <div className={styles.groupTitle}>Time Periods</div>
                <ul>
                  {timePeriodList?.map((tag) => {
                    return (
                      <li
                        className={classNames({
                          [styles.activeTag]: filterTimePeriod === tag?.title,
                        })}
                        key={tag?.id}
                        onClick={() => {
                          setShowFilterList(
                            filterTimePeriod !== tag?.title ? false : true
                          );
                          setFilterTimePeriod(
                            filterTimePeriod !== tag?.title ? tag?.title : ""
                          );
                          setFilterTag("");
                        }}
                      >
                        <span>{tag?.date}</span> {tag?.displayTitle}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className={styles.scrollContainer}>
              <Scroller
                className={"hidden-mobile"}
                direction="vertical"
                ref={filterListInnerContainer}
                scrollBarColor={"light"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const setStoryTags = (stories) => {
    stories?.map((story) => {
      story.tags = [];
      story.topicTag?.map((tag) => {
        story.tags.push(tag.title);
      });
      if (!Array.isArray(story.displayedTag))
        story.displayedTag = [
          story.tags[Math.floor(randomSeed * story.tags.length)],
        ];
      if (Array.isArray(story.timePeriod))
        story.timePeriod = story.timePeriod[0]?.title;
    });
  };

  const assignCardData = (cardData, featured) => {
    const filteredTag =
      (filterTag || filterTimePeriod) && !featured
        ? [filterTag || filterTimePeriod]
        : null;
    if (cardData.children) {
      const pageLink = {
        id: cardData.children[0]?.id,
        uri: cardData.children[0]?.uri,
      };
      return (
        <Card
          caption={
            cardData.shortDescription || cardData.children[0]?.shortDescription
          }
          horizontalLayout={featured}
          image={
            cardData.coverImage?.[0] || cardData.children[0]?.coverImage?.[0]
          }
          imageObjectFit={
            cardData.coverImage?.[0] ? cardData.coverImageCropStyle : null
          }
          imageObjectPosition={
            cardData.coverImage?.[0]
              ? cardData.coverImagePositionOverride
              : null
          }
          key={cardData.id}
          pageLink={pageLink}
          showTags={showTags}
          storyType={cardData.storyType}
          tags={filteredTag || cardData.displayedTag}
          theme={Theme.Black}
          title={cardData.title}
        />
      );
    } else {
      const pageLink = { id: cardData.id, uri: cardData.uri };
      return (
        <Card
          caption={cardData.shortDescription}
          horizontalLayout={featured}
          image={cardData.coverImage?.[0]}
          imageObjectFit={cardData.coverImageCropStyle}
          imageObjectPosition={cardData.coverImagePositionOverride}
          key={cardData.id}
          pageLink={pageLink}
          showTags={showTags}
          storyType={cardData.storyType}
          tags={filteredTag || cardData.displayedTag}
          theme={Theme.Black}
          title={cardData.title}
        />
      );
    }
  };

  const cardsData = storyData?.children;
  setStoryTags(cardsData);
  const displayedCards = cardsData
    ?.filter(
      (cardData) =>
        (!filterTag && !filterTimePeriod) ||
        (filterTag && cardData.tags?.includes(filterTag)) ||
        (filterTimePeriod && cardData.timePeriod === filterTimePeriod)
    )
    .map((cardData) => {
      return assignCardData(cardData, false);
    });

  const featuredStory = storyData?.featuredStory?.[0];
  if (featuredStory) {
    featuredStory.tags = [];
    featuredStory.topicTag?.map((tag) => {
      featuredStory.tags.push(tag.title);
    });
    if (!Array.isArray(featuredStory.displayedTag))
      featuredStory.displayedTag = [
        featuredStory.tags[Math.floor(randomSeed * featuredStory.tags.length)],
      ];
  }
  const featuredStoryCard = featuredStory
    ? assignCardData(featuredStory, true)
    : null;

  const tagList = setTagList("topic");
  const timePeriodList = setTagList("timePeriods");

  useEffect(() => {
    setShowTags(true);
  });

  // hides filter list if open and clicked outside of
  useEffect(() => {
    function handleClickOutside(event) {
      if (showFilterList) {
        if (
          filterWrapperRef.current &&
          !filterWrapperRef.current?.contains(event.target) &&
          !filterBoxRef.current?.contains(event.target)
        ) {
          setShowFilterList(false);
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterWrapperRef, filterBoxRef, showFilterList]);

  return (
    <div data-testid="stories-landing-page">
      {featuredStoryCard && (
        <div className={`container-fluid ${[styles.featuredStory]}`}>
          <h2>Featured Story</h2>
          {featuredStoryCard}
        </div>
      )}
      <div className={`container-fluid ${[styles.storyTitle]}`}>
        <h2>All Stories</h2>
      </div>
      {displayFilterTags()}
      <CardsScrollWrapper key={storyData?.id} layout="stories">
        {displayedCards}
      </CardsScrollWrapper>
    </div>
  );
};

StoriesLandingPageContent.propTypes = {
  storyData: PropTypes.object,
  storyTagData: PropTypes.array,
};

StoriesLandingPageContent.defaultProps = {};

export default StoriesLandingPageContent;
