import classNames from "classnames";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./jump-point-navigation-bar.module.scss";

const JumpPointNavigationBar = ({ jumpPointNames }) => {
  const classNameOuter = classNames(
    styles.jumpPointNavigationBar,
    "container-fluid"
  );

  return (
    <div className={classNameOuter} data-testid="jump-point-navigation-bar">
      <div className={styles.jumpToTitle}>JUMP TO</div>
      <div className={styles.linkBarContainer}>
        {jumpPointNames.map((jumpPointName) => {
          return (
            <div className={styles.jumpPointLinkContainer} key={jumpPointName}>
              <Link
                className={styles.jumpPointLink}
                data-testid="jump-point-link"
                to={`#${jumpPointName}`}
              >
                {jumpPointName}
                <i className={`${styles.downArrow} icon-arrow-down`} />
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

JumpPointNavigationBar.propTypes = {
  jumpPointNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  stickyNav: PropTypes.bool,
};

//Does some error handling to translate from craft object into correct JumpPointNavigationBar data
function convertJumpPointNavigationBar(componentList) {
  if (componentList != null && componentList != undefined) {
    const jumpPointNames = componentList
      ?.filter(
        (c) => c.__typename === "CraftAPI_componentList_jumpPoint_BlockType"
      )
      .map((c) => c.jumpPointName);

    //check if we should render component
    if (jumpPointNames.length === 0) {
      return null;
    } else {
      return <JumpPointNavigationBar jumpPointNames={jumpPointNames} />;
    }
  }
}

export { convertJumpPointNavigationBar, JumpPointNavigationBar as default };
