import PropTypes from "prop-types";

import * as styles from "./theme.module.scss";

/** Theme constant for black background with light text */
const Black = "black";
const Afrofuturism = "afrofuturism";
/** Theme constant for dark background with light text */
const Dark = "dark";
/** Theme constant for light background with dark text */
const Light = "light";
/** Theme constant for light background with dark text */
const White = "white";
/** Theme constant for subtle text on any background */
const Subtle = "subtle";

/** Constant for theme property name */
const Prop = "theme";

// group together themes that share dark backgrounds
const DarkBackgrounds = [Black, Dark, Afrofuturism];

/** PropType to be used by components that are themeable */
const PropType = {
  theme: PropTypes.oneOf([Black, Dark, Light, Subtle, White, Afrofuturism]),
};

/**
 * Apply the CSS class(es) based on the provided theme and background option
 *
 * @param {String} theme the theme to apply
 * @param {Boolean} withBg  whether to apply background color theming
 * @param {Boolean} bgFullWidth background color spans full width of screen
 * @returns {{}}  a CSS class object to be used with classnames
 */
const addClass = (theme, withBg, bgFullWidth) => ({
  [styles.black]: theme === Black || theme === Afrofuturism,
  [styles.dark]: theme === Dark,
  [styles.light]: theme === Light,
  [styles.white]: theme === White,
  [styles.subtle]: theme === Subtle,
  [styles.withBackground]: withBg,
  [styles.bgFullWidth]: bgFullWidth,
});

export {
  addClass,
  Afrofuturism,
  Black,
  Dark,
  DarkBackgrounds,
  Light,
  Prop,
  PropType,
  Subtle,
  White,
};
