// extracted by mini-css-extract-plugin
export var afroGlow = "exhibit-hero-module--afroGlow--EHKdi";
export var darkFont = "exhibit-hero-module--darkFont--dX4u4";
export var grid = "exhibit-hero-module--grid--YsI+S";
export var gridText = "exhibit-hero-module--gridText--I5cLS";
export var hero = "exhibit-hero-module--hero--E89Ow";
export var imageContainer = "exhibit-hero-module--imageContainer--7GG0A";
export var lightFont = "exhibit-hero-module--lightFont--JYlm0";
export var noText = "exhibit-hero-module--noText--PPncD";
export var nuba = "exhibit-hero-module--nuba---b+O3";
export var reduceTopPadding = "exhibit-hero-module--reduceTopPadding--GLzsb";
export var subtitle = "exhibit-hero-module--subtitle--FAymx";
export var textClass = "exhibit-hero-module--textClass--GuPx0";
export var textContainer = "exhibit-hero-module--textContainer--Bxqhs";
export var themedFonts = "exhibit-hero-module--themedFonts--zM1Yg";
export var titleDate = "exhibit-hero-module--title-date---pHyZ";
export var titlesContainer = "exhibit-hero-module--titlesContainer--mUlnq";
export var unformattedThemedFonts = "exhibit-hero-module--unformattedThemedFonts--CjNsn";
export var withBg = "exhibit-hero-module--withBg--5qxBF";