import { NMAAHCPropTypes, Theme, ThemeContext } from "assets";
import { FormattedText, Scroller, SectionNavBlock } from "atoms";
import classNames from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";

import * as styles from "./story-hero.module.scss";

const StoryHero = ({
  activeNavItemId,
  image,
  imageObjectFit,
  imageObjectPosition,
  navItems,
  longDescription,
  title,
  heroSubtype,
  storyType,
  tags,
}) => {
  const blocksRef = useRef();
  const { theme } = useContext(ThemeContext);

  const containerClass = classNames(
    "col-xs-12",
    styles.heroContainer,
    image ? "" : Theme.addClass(Theme.Dark, true),
    storyType ? Theme.addClass(Theme.Black, true) : ""
  );

  const heroWrapperCtn = classNames({
    [styles.storyHeroWrapper]: heroSubtype !== "exhibitionsPage",
    [styles.exhibitionsPageHeroWrapper]: heroSubtype === "exhibitionsPage",
  });

  const displayTags = () => {
    return tags?.map((tag, i) => {
      return <li key={i}>{tag}</li>;
    });
  };

  return (
    <div
      className={classNames(
        "container-fluid",
        storyType === "Present to Past" ? Theme.addClass(Theme.Black, true) : ""
      )}
      data-testid="story-hero"
    >
      <div className={`row center-xs ${heroWrapperCtn}`}>
        <section className={containerClass}>
          <div className={styles.layer}>
            <div className={styles.content}>
              <FormattedText
                className={styles.storyType}
                text={storyType}
                theme={theme}
              />
              <h1>{title}</h1>
              <FormattedText text={longDescription} theme={theme} />
              <ul className={styles.tags}>{displayTags()}</ul>
            </div>
          </div>
          <GatsbyImage
            alt={image?.altText}
            className={styles.storyHero}
            data-testid="text-hero-image"
            formats={["auto", "webp", "avif"]}
            image={getImage(image?.imageFile)}
            layout="fixed"
            objectFit={imageObjectFit}
            objectPosition={imageObjectPosition}
            style={{
              gridArea: "1/1",
            }}
          />
          <div className={styles.layer}>
            <div className={styles.overlay} />
          </div>
        </section>
      </div>
      {storyType !== "Present to Past" && (
        <div className="row center-xs">
          <div className={`col-xs ${styles.navWrapper}`}>
            <div className={styles.blocks} ref={blocksRef}>
              {navItems.map(({ id, title, uri }, i) => (
                <SectionNavBlock
                  active={id === activeNavItemId}
                  key={i}
                  subtitle={`0${i + 1}`}
                  title={title}
                  to={`/${uri}`}
                />
              ))}
            </div>
            <div className={styles.scrollContainer}>
              <Scroller ref={blocksRef} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StoryHero;

StoryHero.propTypes = {
  activeNavItemId: PropTypes.string,
  heroSubtype: PropTypes.string,
  image: NMAAHCPropTypes.Image,
  imageObjectFit: PropTypes.oneOf(["contain", "cover", "none", "scale-down"]),
  imageObjectPosition: PropTypes.string,
  longDescription: PropTypes.string,
  navItems: PropTypes.arrayOf(PropTypes.shape()),
  storyType: PropTypes.string,
  tags: PropTypes.array,
  title: PropTypes.string.isRequired,
};

StoryHero.defaultProps = {
  navItems: [],
};
