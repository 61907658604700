import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./jump-point.module.scss";

// CSS magic makes this take into account subnav height be aware
const JumpPoint = ({ idAndName }) => {
  return (
    <div className={styles.divAnchor} data-testid="jump-point" id={idAndName} />
  );
};

JumpPoint.propTypes = {
  idAndName: PropTypes.string.isRequired,
};

JumpPoint.defaultProps = {
  idAndName: "",
};

/**
 * The GraphQL fragment for retrieving JumpPoint data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const JumpPointFragment = graphql`
  fragment JumpPointFragment on CraftAPI_componentList_jumpPoint_BlockType {
    jumpPointName
  }
`;

const convert = (data) => <JumpPoint idAndName={data.jumpPointName} />;

export { convert, JumpPoint as default, JumpPointFragment };
