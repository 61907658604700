import { Theme } from "assets";
import { NMAAHCModalWindow, VideoPlayer } from "atoms";
import PropTypes from "prop-types";
import React from "react";

const VideoModal = ({
  showModal,
  onClose,
  title,
  subtitle,
  label,
  theme,
  src,
  credit,
  caption,
  loop,
}) => {
  return (
    <NMAAHCModalWindow
      label={label}
      onClose={onClose}
      showModal={showModal}
      subtext={credit}
      subtitle={subtitle}
      text={caption}
      theme={theme}
      title={title}
    >
      <div className="row center-xs">
        <div className="col-xs-12 col-md-8">
          <VideoPlayer loop={loop} muted={false} src={src} autoplay ui />
        </div>
      </div>
      {/**
       * Unfortunately, due to an open issue with the react-modal library users are
       * unable to tab into an embedded iframe if it is the last element in the modal.
       * https://github.com/reactjs/react-modal/issues/226
       * A better workaround might be to implement the custom controls the design team
       * had originally envisioned but for now, adding a tabbable div after the iframe seems to work ok.
       */}
      <div tabIndex="0"></div>
    </NMAAHCModalWindow>
  );
};

VideoModal.propTypes = {
  label: PropTypes.string,
  loop: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  ...Theme.PropType,
};

VideoModal.defaultProps = {
  label: "Video modal",
};

export default VideoModal;
