import { flattenImageFields } from "assets";
import {
  ConstellationGroup,
  convertConstellationComponentList,
} from "organisms";
import React from "react";

/**
 * Converts the provided constellation object data into an active object modal
 *
 * @param constellationObjectData the GraphQL response data
 * @returns                       the constellation object modal
 */
const convert = (constellationObjectData) => {
  // include the cover image with individual component data
  let componentList = constellationObjectData.constellationComponentList?.map(
    (c) => ({
      coverImageFull: constellationObjectData.coverImageFull,
      title: constellationObjectData.title,
      ...c,
    })
  );

  return (
    <ConstellationGroup
      key={constellationObjectData.id}
      objects={constellationObjectData.parent.objects}
      {...flattenImageFields(constellationObjectData)}
      description={
        constellationObjectData?.text ||
        constellationObjectData?.shortDescription
      }
      mainImage={constellationObjectData?.coverImageFull?.[0]}
    >
      {convertConstellationComponentList(componentList)}
    </ConstellationGroup>
  );
};

export { convert };
