import { ActionButton, ChronoBullet, ChronoTrack } from "atoms";
import classNames from "classnames";
import { navigate } from "gatsby";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

import * as styles from "./chrono-slider.module.scss";

const ChronoSlider = ({ activePageId, data, enableFill, reverse }) => {
  // sort data by label (expects 4-digit year format)
  data.sort((a, b) => {
    let result = 0;
    if (reverse) {
      result = b.label - a.label;
    } else {
      result = a.label - b.label;
    }
    return result;
  });
  // assign value marker for map range placement
  data.forEach((datum, i) => (datum.value = `${i * 5}`));
  const values = data.map((datum, i) => datum.value);
  const dragInstance = useRef(null);
  const dragTarget = useRef(null);
  const fill = useRef(null);
  const MIN_VALUE = parseFloat(values[0]);
  const MAX_VALUE = parseFloat(values[values.length - 1]);
  const HORIZONTAL_CHRONO_BULLET_WIDTH = 8;

  // if reverse then use last datum first, otherwise, use first datum first
  let initialChronoLabel = data[0].label;
  let initialChronoValue = data[0].value;

  // if there is a matching activePageId then let's set that datum as the initial value instead
  data.find((datum) => {
    if (datum.id === activePageId) {
      initialChronoLabel = datum.label;
      initialChronoValue = datum.value;
    }
  });

  const [currentChronoLabel, setCurrentChronoLabel] =
    useState(initialChronoLabel);
  const [currentChronoValue, setCurrentChronoValue] =
    useState(initialChronoValue);
  const [leftPositions, setLeftPositions] = useState([]);
  const [sliderTrackParams, setSliderTrackParams] = useState({
    left: "0px",
    width: "100%",
  });

  const [
    displayChronoKnobToRemoveUglyInitialPositionJump,
    setDisplayChronoKnobToRemoveUglyInitialPositionJump,
  ] = useState(false);

  // store left positions for chrono slider to reference in state
  const updateLeftPositions = (_dragInstance, _dragTarget) => {
    const result = values.map((value) => {
      // get left position percentage for each map range number
      let num = gsap.utils.mapRange(MIN_VALUE, MAX_VALUE, 0, 100, value) + "%";
      // convert each map range left position percentage to a real number
      num = (parseFloat(num) / 100) * _dragInstance.maxX;
      // add in half of the width of the chrono knob, so that the bullet is positioned in the middle of the knob
      num += _dragTarget.clientWidth / 2;
      // also compensate for the bullet border width!
      num -= HORIZONTAL_CHRONO_BULLET_WIDTH;
      return num;
    });
    setLeftPositions(result);
    setSliderTrackParams({
      left: result[0] + "px",
      width: result[result.length - 1] - result[0] + "px",
    });
  };

  // update fill width
  const updateFill = (width) => {
    gsap.to(fill.current, { duration: 0.2, width });
  };

  // when chrono knob is dragged
  const onChronoKnobDrag = (instance, snap) => {
    // get values map range relative value
    const relativeValue = gsap.utils.mapRange(
      0,
      instance.maxX,
      MIN_VALUE,
      MAX_VALUE,
      instance.x
    );
    // select values item that is closest to relative value x position
    const finalValue = gsap.utils.snap(values, relativeValue);
    // store current chrono value in state
    setCurrentChronoValue(finalValue);
    // current chrono label in state
    const { label, path } = data.find((datum) => datum.value === finalValue);
    setCurrentChronoLabel(label);
    const snapX = gsap.utils.mapRange(
      MIN_VALUE,
      MAX_VALUE,
      0,
      instance.maxX,
      finalValue
    );
    // if snap is enabled
    if (snap) {
      // quickly slide chrono knob to final value
      gsap.to(dragTarget.current, {
        duration: 0.2,
        x: snapX,
        onComplete: () => onSnapComplete({ path }),
      });
    } else {
      // this use-case typically occurs when dragging the knob
    }
    // if fill is enabled
    if (enableFill) {
      // update fill width
      const fillWidth = snapX + dragTarget.current.clientWidth / 2;
      updateFill(fillWidth);
    }
  };

  // when chrono bullet is clicked
  const onChronoBulletClick = (targetLeftPosition, targetChronoValue) => {
    // grab bullet left position
    let newLeftPosition = parseFloat(targetLeftPosition);
    // center the knob over the bullet
    newLeftPosition -= dragTarget.current.clientWidth / 2;
    // store current chrono value in state
    setCurrentChronoValue(targetChronoValue);
    const { label, path } = data.find(
      (datum) => datum.value === targetChronoValue
    );
    setCurrentChronoLabel(label);
    // if fill is enabled
    // update fill width
    if (enableFill) {
      const fillWidth = newLeftPosition + dragTarget.current.clientWidth / 2;
      updateFill(fillWidth);
    }

    // slide to new left position
    gsap.to(dragTarget.current, {
      x: newLeftPosition,
      onComplete: () => onSnapComplete({ path }),
    });
  };

  const onSnapComplete = ({ path }) => {
    navigate(`/${path}`);
  };

  // get either the next value datum index or the previous value datum index based on the arrow key pressed
  const onChronoKnobKeyDown = (key) => {
    if (key === "ArrowLeft" || key === "ArrowRight") {
      let targetIndex;
      values.find((value, i) => {
        // look up the value datum index of currentChronoValue
        if (value === currentChronoValue) {
          switch (key) {
            case "ArrowLeft":
              // decrease current chrono value by one index
              targetIndex = i - 1;
              // (loop around if target index is less than zero)
              targetIndex = targetIndex < 0 ? values.length - 1 : targetIndex;
              break;
            case "ArrowRight":
              // increase current chrono value by one index
              targetIndex = i + 1;
              // (loop around if target index is more than values length)
              targetIndex = targetIndex > values.length - 1 ? 0 : targetIndex;
              break;
          }
          return true;
        }
      });
      const targetLeftPosition = leftPositions[targetIndex];
      const targetChronoValue = values[targetIndex];
      onChronoBulletClick(targetLeftPosition, targetChronoValue);
    }
  };

  // tab to either the next bullet or previous bullet based on the arrow key pressed
  const onChronoBulletKeyDown = (key, target, i) => {
    if (key === "ArrowLeft" || key === "ArrowRight") {
      const chronoBullets =
        target.parentElement.parentElement.querySelectorAll("[class*=bullet]");
      let targetIndex;
      switch (key) {
        case "ArrowLeft":
          // decrease current chrono value by one index
          targetIndex = i - 1;
          // (loop around if target index is less than zero)
          targetIndex = targetIndex < 0 ? values.length - 1 : targetIndex;
          break;
        case "ArrowRight":
          // increase current chrono value by one index
          targetIndex = i + 1;
          // (loop around if target index is more than values length)
          targetIndex = targetIndex > values.length - 1 ? 0 : targetIndex;
          break;
      }
      chronoBullets[targetIndex].focus();
    }
  };

  // when component is mounted
  useEffect(() => {
    // bind Draggable object to gsap api
    gsap.registerPlugin(Draggable);
    // enable chrono knob (dragTarget.current) to be draggable
    const renderDraggable = () => {
      const area = document.querySelector("[class*=area]");
      dragInstance.current = Draggable.create(dragTarget.current, {
        type: "x",
        edgeResistance: 1,
        bounds: area,
        throwProps: false,
        onDrag: function () {
          onChronoKnobDrag(this, false);
        },
        onDragEnd: function () {
          onChronoKnobDrag(this, true);
        },
      });
      // prepend truthy dragInstance.current[0] condition to pass jest test
      if (dragInstance.current[0]) {
        // update the left positions (this is particularly important call after the page is resized)
        updateLeftPositions(dragInstance.current[0], dragTarget.current);

        // if fill enabled
        // update fill
        if (enableFill) {
          const snapX = gsap.utils.mapRange(
            MIN_VALUE,
            MAX_VALUE,
            0,
            dragInstance.current[0].maxX,
            currentChronoValue
          );
          const fillWidth = snapX + dragTarget.current.clientWidth / 2;
          updateFill(fillWidth);
        }
      }
    };
    renderDraggable();
    // on window resize, re-render the draggable instance
    window && window.addEventListener("resize", renderDraggable);
  }, []);

  // when the left positions get updated
  useEffect(() => {
    const autoRepositionChronoKnob = () => {
      // look up left position of currentChronoValue and update the dragTarget x position
      let newLeftPosition;
      values.find((value, i) => {
        if (value === currentChronoValue) {
          newLeftPosition = leftPositions[i];
          return true;
        }
      });
      // subtract half of the chrono knob width from the new left position to better horizontally align it in front of the chrono bullets
      newLeftPosition -= dragTarget.current.clientWidth / 2;
      // account for the chrono bullet width that the chrono knob is in front of
      newLeftPosition += HORIZONTAL_CHRONO_BULLET_WIDTH;
      // slide the chrono knob to the new left position
      const props = {
        x: newLeftPosition,
      };
      // this condition is here to prevent the knob from jumping back to leftPosition "0" before the correct leftPosition is rendered every time the page loads.
      // without this condition here then the knob flashes to the left for a split-second before it is placed at the correct left position.
      // this jump is very distracting and without the below setTimeout, the chrono navigation feels slightly broken, visiually.
      // But it's not! So let's play with the timing and css effects to give the chrono navigation the polish it needs.
      if (!displayChronoKnobToRemoveUglyInitialPositionJump) {
        props.onComplete = () => {
          const myTimeout = setTimeout(() => {
            setDisplayChronoKnobToRemoveUglyInitialPositionJump(true);
            clearTimeout(myTimeout);
          }, 1);
        };
      }
      // prepend truthy gsap set condition to pass jest test
      gsap.set && gsap.set(dragTarget.current, props);

      // if fill enabled
      // update fill
      if (enableFill) {
        const fillWidth = newLeftPosition + dragTarget.current.clientWidth / 2;
        updateFill(fillWidth);
      }
    };
    autoRepositionChronoKnob();
  }, [leftPositions]);

  // display chronoKnob when displayChronoKnobToRemoveUglyInitialPositionJump is true
  const chronoKnobClassName = classNames({
    [styles.chronoKnob]: true,
    [styles.displayChronoKnob]:
      displayChronoKnobToRemoveUglyInitialPositionJump,
  });

  return (
    <div className={styles.chronoSlider} data-testid="chrono-slider">
      <div className="row middle-sm">
        <div className="col-sm">
          <div className={styles.values}>
            {leftPositions.map((leftPosition, i) => (
              <div
                className={styles.chronoBullet}
                key={i}
                style={{ left: leftPosition }}
              >
                <ChronoBullet
                  isHighlighted={currentChronoValue === values[i]}
                  label={data[i].label}
                  onClick={({ target }) => {
                    onChronoBulletClick(
                      target.parentElement.parentElement.style.left,
                      values[i]
                    );
                  }}
                  onKeyDown={({ key, target }) =>
                    onChronoBulletKeyDown(key, target, i)
                  }
                  isCurrent
                />
              </div>
            ))}
            <div className={styles.area}>
              <div className={chronoKnobClassName} ref={dragTarget}>
                <ActionButton
                  icon="drag"
                  onKeyDown={({ key }) => onChronoKnobKeyDown(key)}
                  text={currentChronoLabel}
                  textPlacement="left"
                  slim
                />
              </div>
              {enableFill && (
                <div className={styles.fill} data-testid="fill" ref={fill} />
              )}
              <div
                className={styles.chronoTrack}
                style={{
                  left: sliderTrackParams.left,
                  width: sliderTrackParams.width,
                }}
              >
                <ChronoTrack />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ChronoSlider.propTypes = {
  activePageId: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  enableFill: PropTypes.bool,
  reverse: PropTypes.bool,
};

ChronoSlider.defaultProps = {
  data: [
    {
      id: "12",
      label: "1980",
      path: "permalink-here",
      value: "0",
    },
  ],
  enableFill: false,
  reverse: false,
};

export default ChronoSlider;
