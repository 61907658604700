// extracted by mini-css-extract-plugin
export var cards = "cards-scroll-wrapper-module--cards--yR2Ku";
export var centerScrollbar = "cards-scroll-wrapper-module--centerScrollbar--SshDs";
export var contentAreaWrapper = "cards-scroll-wrapper-module--contentAreaWrapper--Z2lLU";
export var leftOffset = "cards-scroll-wrapper-module--leftOffset--cQGMO";
export var rightOffset = "cards-scroll-wrapper-module--rightOffset--cXXgn";
export var scrollContainer = "cards-scroll-wrapper-module--scrollContainer--Y879G";
export var scrollContentArea = "cards-scroll-wrapper-module--scrollContentArea--mVfgy";
export var staggerCardHeights = "cards-scroll-wrapper-module--stagger-card-heights--MKtw4";
export var storiesLayout = "cards-scroll-wrapper-module--storiesLayout--Mq608";
export var titleArea = "cards-scroll-wrapper-module--titleArea--wYPZ-";
export var twoColContentArea = "cards-scroll-wrapper-module--twoColContentArea--jsu5u";