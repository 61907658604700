import { NMAAHCPropTypes, Theme } from "assets";
import classNames from "classnames";
import { graphql } from "gatsby";
import { ConstrainedSizeImage, ExpandableImage } from "molecules";
import PropTypes from "prop-types";
import React from "react";

import * as styles from "./full-width-single-image.module.scss";

const FullWidthSingleImage = ({
  backgroundCover,
  backgroundImageUrl,
  fontColor,
  image,
  imageCaption,
  imageObjectFit,
  imageObjectPosition,
  label,
  modalCaptionOnly,
  modalText,
  subtitle,
  title,
  componentSpacing,
}) => {
  const containerClass = classNames("row", styles.verticalMiddle, {
    [styles.imageComponentSpacer]: componentSpacing,
    [styles.backgroundImage]: backgroundImageUrl,
    [styles.backgroundCover]: backgroundCover,
    [styles.darkFont]: fontColor == "dark",
    [styles.lightFont]: fontColor == "light",
  });

  return (
    <div
      className={containerClass}
      data-testid="full-width-single-image"
      style={{ backgroundImage: `url(${backgroundImageUrl})` }}
    >
      <div className="col-xs">
        <ConstrainedSizeImage image={image}>
          <ExpandableImage
            image={image}
            imageCaption={imageCaption}
            imageLayout={"constrained"}
            imageObjectFit={imageObjectFit}
            imageObjectPosition={imageObjectPosition}
            label={label}
            modalCaptionOnly={modalCaptionOnly}
            modalText={modalText}
            subtitle={subtitle}
            title={title}
          />
        </ConstrainedSizeImage>
      </div>
    </div>
  );
};

FullWidthSingleImage.propTypes = {
  backgroundCover: PropTypes.bool,
  backgroundImageUrl: PropTypes.string,
  componentSpacing: PropTypes.bool,
  fontColor: PropTypes.string,
  image: NMAAHCPropTypes.Image,
  imageCaption: PropTypes.string,
  imageObjectFit: PropTypes.oneOf(["contain", "cover", "none", "scale-down"]),
  imageObjectPosition: PropTypes.string,
  label: PropTypes.string,
  modalCaptionOnly: PropTypes.bool,
  modalText: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  ...Theme.PropType,
};

FullWidthSingleImage.defaultProps = {
  theme: Theme.Dark,
  label: "Image",
  componentSpacing: true,
};

const FullWidthSingleImageFragment = graphql`
  fragment FullWidthSingleImageFragment on CraftAPI_componentList_fullWidthSingleImage_BlockType {
    id
    image {
      ...ImageMetadataFragment
    }
    imageCaption
    modalImageText
    imageCropStyle
    backgroundCover
    backgroundImage {
      url
    }
    fontColor
  }
`;

const convert = (data) => {
  return (
    <FullWidthSingleImage
      backgroundCover={data.backgroundCover}
      backgroundImageUrl={data.backgroundImage?.[0]?.url}
      fontColor={data.fontColor}
      image={data.image?.[0]}
      imageAlt={data.image?.[0]?.altText}
      imageCaption={data.imageCaption}
      imageObjectFit={data.imageCropStyle}
      imageObjectPosition={data.imagePositionOverride}
      key={data.id}
      modalText={data.modalImageText}
    />
  );
};

export {
  convert,
  FullWidthSingleImage as default,
  FullWidthSingleImageFragment,
};
