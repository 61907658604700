import { flattenImageFields, NMAAHCPropTypes } from "assets";
import { graphql } from "gatsby";
import { VideoPromo } from "molecules";
import PropTypes from "prop-types";
import React from "react";

const ConstellationVideoPromo = ({ coverImageFull, title, youtubeUrl }) => (
  <div className="row center-xs" data-testid="constellation-video-promo">
    <div className="col-xs">
      <VideoPromo
        coverImage={{ ...coverImageFull, landscape: coverImageFull?.imageFile }}
        screenReaderText={title}
        src={youtubeUrl}
        withComponentSpacing={false}
        isConstellation
      />
    </div>
  </div>
);

ConstellationVideoPromo.propTypes = {
  coverImageFull: NMAAHCPropTypes.Image,
  title: PropTypes.string,
  youtubeUrl: PropTypes.string.isRequired,
};

/**
 * The GraphQL fragment for retrieving video promo data.
 * So long as this is exported with a matching name, Gatsby can make use of it.
 */
const ConstellationVideoPromoFragment = graphql`
  fragment ConstellationVideoPromoFragment on CraftAPI_constellationComponentList_video_BlockType {
    youtubeUrl
  }
`;

const convert = (videoData) => {
  return (
    <ConstellationVideoPromo
      key="constellation-component"
      {...flattenImageFields(videoData)}
    />
  );
};

export {
  ConstellationVideoPromoFragment,
  convert,
  ConstellationVideoPromo as default,
};
